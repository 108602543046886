export const explorers = {
  1: 'https://etherscan.io/',
  137: 'https://polygonscan.com/',
  100: 'https://blockscout.com/xdai/mainnet/',
  250: 'https://ftmscan.com/',
  56: 'https://bscscan.com/',
  1313161554: 'https://aurorascan.dev/',
  43114: 'https://snowtrace.io/',
  42161: 'https://arbiscan.io/',
  10: 'https://optimistic.etherscan.io/',
  1284: 'https://moonbeam.moonscan.io/',
  42220: 'https://explorer.celo.org/',
  122: 'https://explorer.fuse.io/'
}