import axios from "axios";

const API_URL = 'https://etherspot.pillarproject.io/';

const unwrapAPIResponse = (response) => {
  return response.data.data;
}

export const fetchSupportedNetworks = async () => {
  return axios.post(
    API_URL,
    {
      query: `{
        supportedNetworks {
          chainId,
          name
        }
      }`,
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ).then(unwrapAPIResponse)
   .then(data => data.supportedNetworks);
}

export const fetchRelayers = async (chainId) => {
  return axios.post(
    API_URL,
    {
      query: `{
        gatewaySenders(chainId: ${chainId}) {
          items {	
            address
            balance
            state
          }
        }
      }`,
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ).then(unwrapAPIResponse)
   .then(data => data.gatewaySenders.items);
}

export const fetchRelayerTransactions = async (chainId, relayerAddress) => {
  return axios.post(
    API_URL,
    {
      query: `{
        gatewaySenderTransactions(chainId: ${chainId}, relayerAddress: "${relayerAddress}") {
          items {
            hash
            chainId
            state
            gasPrice
            createdAt
            updatedAt
          }
        }
      }`,
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ).then(unwrapAPIResponse)
  .then(data => data.gatewaySenderTransactions.items);
}

export const fetchAllRelayerTransactions = async () => {
  return axios.post(
    API_URL,
    {
      query: `{
        allGatewayPendingTransactions {
          items {
            hash
            chainId
            sender
            state
            gasPrice
            createdAt
            updatedAt
          }
        }
      }`,
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ).then(unwrapAPIResponse)
  .then(data => data.allGatewayPendingTransactions.items);
}
