import "./App.css";
import { useEffect, useState, useCallback } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { fetchRelayers, fetchAllRelayerTransactions, fetchSupportedNetworks } from './api';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { explorers } from "./config";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const BusyTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
    color: 'red',
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  table1: {
    minWidth: 700,
  }
});

const App = () => {
  const classes = useStyles();
  const [chains, setChains] = useState([]);
  const [relayers, setRelayers] = useState([]);
  const [selectedChains, setSelectedChains] = useState([]);
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [selectedRelayerAddress, setSelectedRelayerAddress] = useState(null);

  const getChainName = (id) => {
    const chain = chains.find(chain => chain.chainId === id);
    return chain && chain.name;
  }

  const getChainId = (name) => {
    const chain = chains.find(chain => chain.name === name);
    return chain && chain.chainId;
  }

  const getPendingTransactions = async () => {
    const relayerPendingTransactions = await fetchAllRelayerTransactions();
    setPendingTransactions([]);
    const pendingTransactions = [];
    await Promise.all(
      relayerPendingTransactions.map((transaction) => {
        if (pendingTransactions[transaction.sender]) {
          pendingTransactions[transaction.sender].transactions.push(transaction);
        } else pendingTransactions[transaction.sender] = { transactions: [transaction] }
        return pendingTransactions;
      }));
    setPendingTransactions(pendingTransactions);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSelectedRelayerAddress(null);
  }
  const handleShow = (address) => {
    setShow(true);
    setSelectedRelayerAddress(address);
    console.log(address, pendingTransactions[address], pendingTransactions);
  }

  const getChains = useCallback(
    async () => {
      const data = await fetchSupportedNetworks();
      setChains(data);
      setSelectedChains(
        data.map(({ name }) => name)
      );
    }
  )

  const getRelayers = useCallback(
    async () => {
      const data = await Promise.all(
        chains.map(chain => fetchRelayers(chain.chainId)),
      );
      const relayers = data.map((relayers, idx) => ({
        chainId: chains[idx].chainId,
        relayers,
      }));
      setRelayers(relayers);
      getPendingTransactions();
    },
    [setRelayers, chains]
  );

  useEffect(() => {
    getChains();
  }, []);

  useEffect(() => {
    getRelayers();
  }, [chains]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleSelectedChainChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedChains(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div className="App">
      <h1>Etherspot Relayers Status Page</h1>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-name-label">Network</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectedChains}
                    onChange={handleSelectedChainChange}
                    input={<OutlinedInput label="Network" />}
                    MenuProps={MenuProps}
                  >
                    {chains.map((chain) => (
                      <MenuItem
                        key={chain.chainId}
                        value={chain.name}
                      >
                        {chain.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </StyledTableCell>
              <StyledTableCell>Relayer</StyledTableCell>
              <StyledTableCell>PendingTransactions</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align="right">State</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              relayers.map(relayer => (
                selectedChains.some(chain => getChainId(chain) === relayer.chainId) &&
                [
                  <StyledTableRow key={relayer.chainId}>
                    <TableCell component="th" scope="row" width={300}>
                      {getChainName(relayer.chainId)}
                    </TableCell>
                    <TableCell component="a" scope="row" href={`${explorers[relayer.chainId]}address/${relayer.relayers[0].address}`} target="_blank">
                      {relayer.relayers[0].address}
                    </TableCell>
                    <TableCell>
                      {pendingTransactions[relayer.relayers[0].address]?.transactions.length ?? 0}
                    </TableCell>
                    <TableCell>
                      {pendingTransactions[relayer.relayers[0].address]?.transactions.length ? (
                        <Button size="small" variant="contained" onClick={() => handleShow(relayer.relayers[0].address)}>
                          Show Transactions
                        </Button>
                      ) : null}
                    </TableCell>
                    <StyledTableCell align="right">
                      {relayer.relayers[0].state}
                    </StyledTableCell>
                  </StyledTableRow>,
                  relayer.relayers.slice(1).map(rlr => (
                    <StyledTableRow key={rlr.address}>
                      <TableCell component="th" scope="row">
                      </TableCell>
                      <TableCell component="a" scope="row" href={`${explorers[relayer.chainId]}address/${rlr.address}`} target="_blank">
                        {rlr.address}
                      </TableCell>
                      <TableCell>
                        {pendingTransactions[rlr.address]?.transactions?.length ?? 0}
                      </TableCell>
                      <TableCell>
                        {pendingTransactions[rlr.address]?.transactions?.length ? (
                          <Button size="small" variant="contained" onClick={() => handleShow(rlr.address)}>
                            Show Transactions
                          </Button>
                        ) : null}
                      </TableCell>
                      {rlr.state === 'Busy' ?
                        <BusyTableCell align="right">
                          {rlr.state}
                        </BusyTableCell> :
                        <StyledTableCell align="right">
                          {rlr.state}
                        </StyledTableCell>
                      }
                    </StyledTableRow>
                  ))
                ]
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Transaction Details"}
        </DialogTitle>
        <DialogContent>
          {selectedRelayerAddress && <DialogContentText id="alert-dialog-description">
            <TableContainer component={Paper}>
              <TableHead>
                <StyledTableCell>
                  Relayer
                </StyledTableCell>
                <StyledTableCell>
                  Hash
                </StyledTableCell>
                <StyledTableCell>
                  GasPrice
                </StyledTableCell>
                <StyledTableCell>
                  Created At
                </StyledTableCell>
                <StyledTableCell>
                  Last Updated At
                </StyledTableCell>
              </TableHead>
              <TableBody>
                {
                  pendingTransactions[selectedRelayerAddress]?.transactions?.map(transaction => ([
                    <StyledTableRow key={transaction.hash}>
                      <TableCell>{transaction.sender}</TableCell>
                      <TableCell>{transaction.hash}</TableCell>
                      <TableCell>{transaction.gasPrice}</TableCell>
                      <TableCell>{transaction.createdAt}</TableCell>
                      <TableCell>{transaction.updatedAt}</TableCell>
                    </StyledTableRow>
                  ]))
                }
              </TableBody>
            </TableContainer>
          </DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default App;
